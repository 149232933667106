import React from "react";
import { createContext, useState, useEffect } from "react";
import axios from "axios";
import { calculateTimeLeft } from "src/views/auth/forget-password-link/timer";
// import ApiConfig from "src/config/APICongig";
import { toast, ToastContainer } from "react-toastify";
export const AuthContext = createContext();
// Check if the stored timestamp is older than two days
function isOlderThanTwoDays(timestamp) {
  // const twoDaysInMilliseconds = 2 * 24 * 60 * 60 * 1000;
  const twoDaysInMilliseconds = 4 * 60 * 60 * 1000;
  const currentTimestamp = new Date().getTime();
  return currentTimestamp - timestamp > twoDaysInMilliseconds;
}

// Set a value in local storage with a timestamp
function setValue() {
  const data = {
    timestamp: new Date().getTime() // Store the current timestamp
  };
  localStorage.setItem("date", JSON.stringify(data));
}

// Clear local storage if the stored timestamp is older than two days
function clearLocalStorageIfOlderThanTwoDays() {
  const storedData = localStorage.getItem("date");
  if (storedData) {
    const data = JSON.parse(storedData);
    if (isOlderThanTwoDays(data.timestamp)) {
      localStorage.clear();
      toast.warn("Session expired.")

    }
  }
}

const setSession = (accessToken) => {
  if (accessToken) {
    setValue();
    localStorage.setItem("creatturAccessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Creattur ${accessToken}`;
  } else {
    localStorage.removeItem("creatturAccessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.localStorage.getItem("creatturAccessToken");
  return accessToken ? true : false;
}


export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [endTime, setEndtime] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const [timeLeft, setTimeLeft] = useState();

  useEffect(() => {
    clearLocalStorageIfOlderThanTwoDays();
    if (endTime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTime));
      }, 1000);
      return () => clearTimeout(timer);
    }
  });

  let data = {
    userLoggedIn: isLogin,
    setEndtime,
    setTimeLeft,
    isLoading,
    timeLeft,
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
