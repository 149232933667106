import React from "react";
import { makeStyles, Box, LinearProgress } from "@material-ui/core";
import SettingsContext from "src/context/SettingsContext";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    backgroundColor: theme.palette.background.dark,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    left: 0,
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 2000,
  },
  loader1: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    maxWidth: "500px",
    padding: "0px 30px",
  },
  pageloadImg: {
    width: "300px",
    height: "150px",
    "@media(max-width:425px)": {
      width: "150px",
      height: "75px",
    },
  },
}));

export default function PageLoading() {
  const classes = useStyles();
  const themeSeeting = React.useContext(SettingsContext);

  return (
    <div className={classes.root}>
      <Box className={classes.loader1}>
        <Box className={classes.loader}>
          {themeSeeting.settings.theme === "DARK" ? (
            <>
              <img
                src="images/logo.svg"
                alt="loader"
                style={{ width: "100%" }}
              />
            </>
          ) : (
            <>
              <img
                src="/images/logo.svg"
                alt="loader"
                className={classes.pageloadImg}
              />
            </>
          )}
        </Box>
      </Box>
    </div>
  );
}
