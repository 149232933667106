import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  logoDiv: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    cursor:"pointer",
  },
  logoflex: {
    borderRadius: "3px",
    padding: "4px 7px",
  },
  weText: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "37.2984px",
    lineHeight: "23px",
    color: "black",
    "@media(max-width:425px)": {
      fontSize: "38px",
    },
  },
  codeText: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "37.2984px",
    lineHeight: "23px",
    color: "black",
    "@media(max-width:425px)": {
      fontSize: "38px",
    },
  },
}));
const Logo = (props) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box
      className={classes.logoDiv}
      onClick={() => {
        history.push("/");
      }}
    >
      <div>
      <img src="/images/logo.svg" />
      </div>
      {/* <Typography className={classes.weText}>We</Typography>
      <div className={classes.logoflex}>
        <Typography className={classes.codeText}>Code</Typography>
      </div> */}
    </Box>
  );
};

export default Logo;
